import React from "react"
import { graphql } from "gatsby"
import { Link} from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import FaqLayout from "../components/layout/faq"

const shortcodes = { Link }

export default function Template({
  data: { mdx },
}) {
  return (
    <FaqLayout>
      <MDXProvider components={shortcodes}>
        <MDXRenderer className="content mx-6">{mdx.body}</MDXRenderer>
      </MDXProvider>
    </FaqLayout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "YYYY年MM月DD日")
        slug
        title
      }
    }
  }
`